$font-family-inter: 'Inter', sans-serif;

$color-text-dark: #232930;
$color-text-grey: #71747A;
$color-text-grey-light: #9CA0A6;
$color-text-light: #FFFFFF;

$color-bg-dark-100: #30343D;
$color-bg-dark-200: #242832;
$color-bg-dark-300: #181C24;

$color-bg-light-100: #FFFFFF;
$color-bg-light-200: #F9F9FA;
$color-bg-light-300: #F5F5F5;
$color-bg-light-400: #E6E6E6;

$color-basic-alfa-8: #F4F4F4;
$color-basic-alfa-16: #E8E9EA;
$color-basic-alfa-24: #DDDEDF;
$color-basic-alfa-32: #D2D3D4;
$color-basic-alfa-48: #BBBCBF;

$color-white-alfa-8: rgba(255, 255, 255, 0.08);
$color-white-alfa-16: rgba(255, 255, 255, 0.16);
$color-white-alfa-24: rgba(255, 255, 255, 0.24);
$color-white-alfa-32: rgba(255, 255, 255, 0.32);
$color-white-alfa-48: rgba(255, 255, 255, 0.48);

$color-primary-400: #6DA8E4;
$color-primary-500: #327DCA;
$color-primary-600: #256FBB;
$color-primary-700: #28609C;
$color-primary-alfa-8: #F0F5FA;
$color-primary-alfa-16: #E1EBF6;
$color-primary-alfa-24: #D1E1F1;
$color-primary-alfa-32: #C2D7EC;

$color-secondary-400: #FC8F77;
$color-secondary-500: #E87860;
$color-secondary-600: #D96146;
$color-secondary-700: #CF5338;
$color-secondary-alfa-8: #FEFCFA;
$color-secondary-alfa-16: #FCF5EF;
$color-secondary-alfa-24: #F9EAE0;
$color-secondary-alfa-32: #F7DDCC;

$color-tertiary-400: #F8D671;
$color-tertiary-500: #F4C343;
$color-tertiary-600: #D1A030;
$color-tertiary-700: #AF8021;
$color-tertiary-alfa-8: #FEFAF0;
$color-tertiary-alfa-16: #FDF5E1;
$color-tertiary-alfa-24: #FCF1D2;
$color-tertiary-alfa-32: #FBECC3;

$color-info-400: #42D0EB;
$color-info-500: #42ABCC;
$color-info-600: #3497B6;
$color-info-700: #0565A0;
$color-info-alfa-8: #EBF8FC;
$color-info-alfa-16: #D8F2FA;
$color-info-alfa-24: #C4EBF7;
$color-info-alfa-32: #B1E5F5;

$color-success-400: #86CF67;
$color-success-500: #6BB658;
$color-success-600: #4F9C40;
$color-success-700: #398C32;
$color-success-alfa-8: #F3F9F2;
$color-success-alfa-16: #E7F3E4;
$color-success-alfa-24: #DBEDD7;
$color-success-alfa-32: #D0E8CA;

$color-warning-400: #FFBE71;
$color-warning-500: #EC9A4B;
$color-warning-600: #D96F1D;
$color-warning-700: #B75B21;
$color-warning-alfa-8: #FFF7F0;
$color-warning-alfa-16: #FFF0E1;
$color-warning-alfa-24: #FFE8D2;
$color-warning-alfa-32: #FFE0C3;

$color-danger-400: #F57C78;
$color-danger-500: #E05960;
$color-danger-600: #C23F52;
$color-danger-700: #AC2646;
$color-danger-alfa-8: #FFF0F1;
$color-danger-alfa-16: #FFE1E2;
$color-danger-alfa-24: #FFD2D4;
$color-danger-alfa-32: #FFC3C5;
