@import 'card';
@import 'dropdown';
@import 'tabs';

/*.popover {
  max-width: 100% !important;
}*/

.popover-body {
  padding: 0.75rem;
}

.ngx-datatable.material {
  .datatable-body .datatable-body-row .datatable-body-cell {
    &.left-align {
      text-align: left !important;
    }

    &.right-align {
      text-align: right !important;
    }

    text-align: center !important;
  }

  .datatable-header .datatable-header-cell {
    text-align: center !important;
    padding-top: 5px !important;
    &.right-align {
      text-align: right !important;
    }
  }

  box-shadow: none !important;
}

