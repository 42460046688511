.p-sidebar {
    background: $overlayContentBg;
    color: $panelContentTextColor;
    border: $overlayContentBorder;
    box-shadow: $overlayContainerShadow;

    .p-sidebar-header {
        &:not(:empty) {
          padding: $panelHeaderPadding;
        }

        .p-sidebar-close,
        .p-sidebar-icon {
            @include action-icon();
        }

        & + .p-sidebar-content {
            padding-top: 0;
        }
    }

    .p-sidebar-content {
        flex-grow: 1;

        &:not(:empty) {
            padding: $panelContentPadding;
        }
    }

    .p-sidebar-footer {
        &:not(:empty) {
          padding: $panelHeaderPadding;
        }
    }
}
