//----------------------------------------------------------------------------------------------------------------
// Include any dependencies here
@use '@angular/material' as mat;
@import 'primeng-ripsaw';
//----------------------------------------------------------------------------------------------------------------
// First thing to do is define the css variables that might be overridden by a theme
:root {
  // vars that could be overridden by themes in the future as css vars
  --default: #ffffff;
  --body-bg: #f0f3f4;
  --default-text: #666666;
  --help-text: #949494;

  --label-text: #ffffff;
  --disabled: #dddddd;
  --disabled-bg: hsl(from var(--disabled) h s calc(l + 10)); // tint
  --border: #dddedf;
  --border-light: hsl(from var(--border) h s calc(l + 15)); // tint
  --input-border: hsl(from var(--border) h s calc(l - 3)); // shade
  --input-background: #ffffff;
  --dropdown-text: #7d7d7d;

  --mail-box: whitesmoke;
  --auth-panel-background: #ffffff;
  --progress-background: rgba(#000000, 0.07);
  --progress-default: rgba(#000000, 0.15);

  --bootstrap-panel-radius: 5px;
  --bootstrap-panel-text: #7d7d7d;
  --bootstrap-panel-bg: #ffffff;
  --bootstrap-panel-header-bg: var(--bootstrap-panel-bg);
  --bootstrap-panel-header-border: 1px solid rgba(0, 0, 0, 0.12);
  --bootstrap-panel-shadow: 0 0.5px 0.5px 0 rgba(0, 0, 0, 0.25);

  // vars that are overridden by themes already as css vars
  --secondary: #0090D4;
  --sidebar: rgb(245, 245, 245);
  --primary: #1B75BC;
  --primary-light: hsl(from var(--primary) h s calc(l + 15)); // tint
  --primary-dark:  rgb(16, 57, 102);     //hsl(from var(--primary) h s calc(l - 15)); // shade
  --primary-bg: hsl(from var(--primary) h s calc(l + 10)); // tint
  --primary-a-500: #00AEEF;
  --primary-a-600: #0090D4;
  --optimizer-blue: var(--primary-dark);
  --optimizer-accent: #39B54A;
  --logo-height: 5rem;
  --logo-padding: 0.5rem 0;
  --sidebar-toggle-top: 12rem;
  --sidebar-width: 0;
  --sidebar-dark: hsl(from var(--secondary) h s calc(l - 15));;
  --sidebar-text: #00AEEF;
}

//----------------------------------------------------------------------------------------------------------------
// define vars that are overridden by themes as scss vars
$sidebar: var(--sidebar);
$secondary: var(--secondary);
$primary: var(--primary);
$primary-a-500: var(--primary-a-500);
$primary-a-600: var(--primary-a-600);
$primary-light: var(--primary-light);
$primary-dark: var(--primary-dark);
$primary-bg: var(--primary-bg);

$optimizer-blue: var(--optimizer-blue);
$optimizer-accent: var(--optimizer-accent);

$logo-height: var(--logo-height);
$logo-padding: var(--logo-padding);

$sidebar-toggle-top: var(--sidebar-toggle-top);
//----------------------------------------------------------------------------------------------------------------
// vars that could be overridden by themes in the future as scss vars
$default: var(--default);
$body-bg: var(--body-bg);

$default-text: var(--default-text);
$content-text: var(--default-text);
$help-text: var(--help-text);

$label-text: var(--label-text);
$disabled: var(--disabled);
$disabled-bg: var(--disabled-bg);
$border: var(--border);
$border-light: var(--border-light);
$input-border: var(--input-border);
$input-background: var(--input-background);
$dropdown-text: var(--dropdown-text);

$sidebar-text: var(--sidebar-text);
$sidebar-dark: var(--sidebar-dark);

$mail-box: var(--mail-box);
$auth-panel-background: var(--auth-panel-background);
$progress-background: var(--progress-background);
$progress-default: var(--progress-default);

$bootstrap-panel-radius: var(--bootstrap-panel-radius);
$bootstrap-panel-text: var(--bootstrap-panel-text);
$bootstrap-panel-bg: var(--bootstrap-panel-bg);
$bootstrap-panel-header-bg: var(--bootstrap-panel-header-bg);
$bootstrap-panel-header-border: var(--bootstrap-panel-header-border);
$bootstrap-panel-shadow: var(--bootstrap-panel-shadow);

$info: #2dacd1 !default;
$success: #90b900 !default;
$warning: #dfb81c !default;
$danger: #e85656 !default;

// aggConnectionManager statuses
$status-success: #6bb658;
$status-success-background: #f3f9f2;
$status-success-border: #dbedd7;
$status-input-required: #e87860;
$status-input-required-background: #fefcfa;
$status-input-required-border: #f9eae0;
$status-aggregation-in-progress: #327dca;
$status-aggregation-in-progress-background: #f0f5fa;
$status-aggregation-in-progress-border: #d1e1f1;
$status-partial-success: #42abcc;
$status-partial-success-background: #ebf8fc;
$status-partial-success-border: #c4ebf7;
$status-failed: #e05960;
$status-failed-background: #fff0f1;
$status-failed-border: #ffd2d4;
$status-failed-recently: #d96f1d;
$status-failed-recently-background: #fff7f0;
$status-failed-recently-border: #ffe8d2;
$status-login-in-progress: #6a6a6a;
$status-login-in-progress-background: #f4f4f4;
$status-login-in-progress-border: #dddedf;

$info-light: tint($info, 30%);
$success-light: tint($success, 30%);
$warning-light: tint($warning, 30%);
$danger-light: tint($danger, 30%);

$info-dark: shade($info, 15%);
$success-dark: shade($success, 15%);
$warning-dark: shade($warning, 15%);
$danger-dark: shade($danger, 15%);

$info-bg: tint($info, 20%);
$success-bg: tint($success, 20%);
$warning-bg: tint($warning, 20%);
$danger-bg: tint($danger, 20%);

$active-link: $primary;
$hover-link: $primary-dark;


@mixin body-bg() {
  background-color: $body-bg;
}

@mixin additional-bg() {
  //display: block; // additional background layer, hidden by default
}

///////////////////////////////////
@import '@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

$ripsaw-app-primary: mat.define-palette($mat-blue); //mat-palette($mat-indigo);
$ripsaw-app-accent: mat.define-palette($mat-light-blue); //), A200, A100, A400);

// The warn palette is optional (defaults to red).
$ripsaw-app-warn: mat.define-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$ripsaw-app-theme: mat.define-light-theme(
                $ripsaw-app-primary,
                $ripsaw-app-accent,
                $ripsaw-app-warn
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($ripsaw-app-theme);

$mat-primary: mat.get-color-from-palette($ripsaw-app-primary);
$mat-warn: mat.get-color-from-palette($ripsaw-app-warn);

// GLOBAL STYLES THAT NEED TO SUPERSEDE MATERIAL

//notifications
.snack-bar {
  background-color: #1B75BCFF;
  //color: #1C2B36;
  font-weight: bold;
  font-size: 14px;
  //max-width: 75vw !important;
  justify-content: center;

  .mat-simple-snackbar {
    justify-content: center;
  }
}

//tooltips
.mat-tooltip-custom {
  background-color: #1B75BCFF; // $sidebar-dark; // $primary;
  font-size: 14px;
  font-weight: bold;
}

.icon-button,
.raised-menu-button {
  color: $primary !important;
  cursor: pointer;
  height: 30px;
  line-height: 10px;
  min-width: 0;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 8px !important;
  margin-right: 0 !important;
  border-radius: 8px;
}

.icon-button {
  width: 40px;
  padding: 0;
}

.mobile-close-btn {
  color: $primary;
}

.mat-raised-button.mat-accent {
  background-color: $optimizer-blue !important;
  color: #ffffff !important;
}

.mat-stroked-button.orange-accent {
  color: $optimizer-accent;
}

//----------------------------------------------------------------------------------------------------------------