.p-toast {
  opacity: $toastOpacity;
  z-index: 100011 !important;

  .p-toast-message {
    margin: $toastMargin;
    box-shadow: $toastShadow;
    border-width: $toastBorderWidth;
    border-radius: $toastBorderRadius;

    .p-toast-message-content {
      padding: $toastPadding;

      .p-toast-message-text {
        margin: $toastMessageTextMargin;
        line-height: 1.5;
      }

      .p-toast-message-icon {
        font-size: $toastIconFontSize;
      }

      .p-toast-summary {
        font-weight: $toastTitleFontWeight;
      }

      .p-toast-detail {
        margin: $toastDetailMargin;
      }
    }

    .p-toast-icon-close {
      width: $toastIconFontSize;
      height: 1em;
      border-radius: $actionIconBorderRadius;
      background: transparent;
      transition: $actionIconTransition;

      &:hover {
        background: rgba(255, 255, 255, 0.3);
      }

      &:focus {
        @include focused();
      }
    }

    &.p-toast-message-info {
      background: $infoMessageBg;
      border: $infoMessageBorder;
      color: $infoMessageTextColor;

      .p-toast-summary {
        color: $infoMessageSummaryColor;
      }

      .p-toast-message-icon {
        color: $infoMessageIconColor;
      }

      .p-toast-icon-close {
        color: $infoMessageCloseIconColor;
      }
    }

    &.p-toast-message-success {
      background: $successMessageBg;
      border: $successMessageBorder;
      color: $successMessageTextColor;

      .p-toast-summary {
        color: $successMessageSummaryColor;
      }

      .p-toast-message-icon {
        color: $successMessageIconColor;
      }

      .p-toast-icon-close {
        color: $successMessageCloseIconColor;
      }
    }

    &.p-toast-message-warn {
      background: $warningMessageBg;
      border: $warningMessageBorder;
      color: $warningMessageTextColor;

      .p-toast-summary {
        color: $warningMessageSummaryColor;
      }

      .p-toast-message-icon {
        color: $warningMessageIconColor;
      }

      .p-toast-icon-close {
        color: $warningMessageCloseIconColor;
      }
    }

    &.p-toast-message-error {
      background: $errorMessageBg;
      border: $errorMessageBorder;
      color: $errorMessageTextColor;

      .p-toast-summary {
        color: $errorMessageSummaryColor;
      }

      .p-toast-message-icon {
        color: $errorMessageIconColor;
      }

      .p-toast-icon-close {
        color: $errorMessageCloseIconColor;
      }
    }
  }
}
