.progress-spinner {
  position: relative;
  min-height: 2rem;
  overflow: hidden !important;

  &:before {
    content: '';
    display: block;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(0.25rem);
  }

  &:after {
    font-family: primeicons;
    content: '\e926';
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    display: block;
    font-size: 1.5rem;
    color: $shade600;
    transform: translate(-50%, -50%);
    animation: progress-spinner-rotation 2s infinite linear;
  }
}

@keyframes progress-spinner-rotation {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(359deg);
  }
}
