$primaryColor: $color-primary-500 !default;
$primaryLightColor: $color-primary-400 !default;
$primaryDarkColor: $color-primary-600 !default;
$primaryDarkerColor: $color-primary-700 !default;
$primaryTextColor: $color-text-light !default;

$highlightBg: #EFF6FF !default;
$highlightTextColor: $primaryDarkerColor !default;

//reused color variables
$shade000: $color-bg-light-100 !default;    //surface
$shade100: $color-bg-light-200 !default;    //header background
$shade200: $color-bg-light-200 !default;    //hover background
$shade300: $color-basic-alfa-16 !default;    //border, divider
$shade400: $shade300 !default;    //input border
$shade500: $color-basic-alfa-32 !default;    //input icon
$shade600: $color-text-grey !default;    //text secondary color
$shade700: $color-text-dark !default;    //text color
$shade800: $color-text-dark !default;    //unused

//global
$fontFamily: $font-family-inter !default;
$fontSize: 0.875rem !default;
$fontWeight: 400 !default;
$textColor: $shade700 !default;
$textSecondaryColor: $shade600 !default;
$borderRadius: 0.375rem !default;
$transitionDuration: .2s !default;
$formElementTransition: background-color $transitionDuration, color $transitionDuration, border-color $transitionDuration, box-shadow $transitionDuration !default;
$actionIconTransition: background-color $transitionDuration, color $transitionDuration, box-shadow $transitionDuration !default;
$listItemTransition: box-shadow $transitionDuration !default;
$primeIconFontSize: 0.875rem !default;
$divider: 1px solid $shade300 !default;
$inlineSpacing: .5rem !default;
$disabledOpacity: .4 !default;
$maskBg: rgba(0, 0, 0, 0.4) !default;
$loadingIconFontSize: 2rem !default;
$errorColor: $color-danger-500 !default;

//scale
$scaleSM: 0.875 !default;
$scaleLG: 1.125 !default;

//focus
$focusOutlineColor: $color-primary-alfa-24 !default;
$focusOutline: 0 none !default;
$focusOutlineOffset: 0 !default;
$focusShadow: 0 0 0 0.125rem $focusOutlineColor !default;

//action icons
$actionIconWidth: 2rem !default;
$actionIconHeight: 2rem !default;
$actionIconBg: transparent !default;
$actionIconBorder: 0 none !default;
$actionIconColor: $shade600 !default;
$actionIconHoverBg: $shade200 !default;
$actionIconHoverBorderColor: transparent !default;
$actionIconHoverColor: $shade800 !default;
$actionIconBorderRadius: 50% !default;

//input field (e.g. inputtext, spinner, inputmask)
$inputPadding: .5rem .75rem !default;
$inputTextFontSize: 1rem !default;
$inputTextLineHeight: 1.25rem !default;
$inputTextFontWeight: 500 !default;

$inputBg: $shade000 !default;
$inputTextColor: $shade700 !default;
$inputIconColor: $shade600 !default;
$inputBorder: 1px solid $shade400 !default;
$inputHoverBorderColor: $shade400 !default;
$inputFocusBorderColor: $shade600 !default;
$inputErrorBorderColor: $errorColor !default;
$inputPlaceholderTextColor: $color-text-grey-light !default;
$inputPlaceholderFontWeight: 400 !default;
$inputFilledBg: $shade200 !default;
$inputFilledHoverBg: $inputFilledBg !default;
$inputFilledFocusBg: $shade000 !default;

//input groups
$inputGroupBg: $shade200 !default;
$inputGroupTextColor: $shade600 !default;
$inputGroupAddOnMinWidth:  3rem !default;

//input lists (e.g. dropdown, autocomplete, multiselect, orderlist)
$inputListBg: $shade000 !default;
$inputListTextColor: $shade700 !default;
$inputListBorder: $inputBorder !default;
$inputListPadding: .5rem 0 !default;
$inputListPanelBorderRadius: 0.625rem !default;
$inputListItemPadding: .5rem 1rem !default;
$inputListItemBg: transparent !default;
$inputListItemFontSize: 0.875rem !default;
$inputListItemTextColor: $shade700 !default;
$inputListItemHoverBg: $color-primary-alfa-16 !default;
$inputListItemTextHoverColor: $color-primary-600 !default;
$inputListItemBorder: 0 none !default;
$inputListItemBorderRadius: 0 !default;
$inputListItemMargin: 0 !default;
$inputListItemFocusShadow: inset 0 0 0 0.15rem $focusOutlineColor !default;
$inputListHeaderPadding: .75rem 1.25rem !default;
$inputListHeaderMargin: 0 !default;
$inputListHeaderBg: $shade100 !default;
$inputListHeaderTextColor: $shade800 !default;
$inputListHeaderBorder: 1px solid $shade300 !default;

//inputs with overlays (e.g. autocomplete, dropdown, multiselect)
$inputOverlayBg: $inputListBg !default;
$inputOverlayHeaderBg: $inputListHeaderBg !default;
$inputOverlayBorder: 0 none !default;
$inputOverlayShadow: 0 2px 12px 0 rgba(0,0,0,.1) !default;

//password
$passwordMeterBg: $shade300 !default;
$passwordWeakBg: $color-danger-500 !default;
$passwordMediumBg: $color-warning-500 !default;
$passwordStrongBg: $color-success-500 !default;

//button
$buttonPadding: .75em 1.25em !default;
$buttonIconOnlyWidth: 2.875rem !default;
$buttonIconOnlyPadding: .75rem 0 !default;
$buttonBg: $primaryColor !default;
$buttonTextColor: $primaryTextColor !default;
$buttonBorder: 1px solid $primaryColor !default;
$buttonHoverBg: $primaryDarkColor !default;
$buttonTextHoverColor: $primaryTextColor !default;
$buttonHoverBorderColor: $primaryDarkColor !default;
$buttonActiveBg: $primaryDarkerColor !default;
$buttonTextActiveColor: $primaryTextColor !default;
$buttonActiveBorderColor: $primaryDarkerColor !default;
$raisedButtonShadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12) !default;
$roundedButtonBorderRadius: 2rem !default;

$textButtonHoverBgOpacity: .04 !default;
$textButtonActiveBgOpacity: .16 !default;
$outlinedButtonBorder: 1px solid !default;
$plainButtonTextColor: $textSecondaryColor !default;
$plainButtonHoverBgColor: $shade200 !default;
$plainButtonActiveBgColor: $shade300 !default;

$secondaryButtonBg: $color-secondary-500 !default;
$secondaryButtonTextColor: $color-text-light !default;
$secondaryButtonBorder: 1px solid $secondaryButtonBg !default;
$secondaryButtonHoverBg: $color-secondary-600 !default;
$secondaryButtonTextHoverColor: $secondaryButtonTextColor !default;
$secondaryButtonHoverBorderColor: $color-secondary-600 !default;
$secondaryButtonActiveBg: $color-secondary-700 !default;
$secondaryButtonTextActiveColor: $secondaryButtonTextColor !default;
$secondaryButtonActiveBorderColor: $color-secondary-700 !default;
$secondaryButtonFocusShadow: 0 0 0 0.2rem $color-secondary-alfa-16 !default;

$infoButtonBg: $color-info-500 !default;
$infoButtonTextColor: $color-text-light !default;
$infoButtonBorder: 1px solid $infoButtonBg !default;
$infoButtonHoverBg: $color-info-600 !default;
$infoButtonTextHoverColor: $infoButtonTextColor !default;
$infoButtonHoverBorderColor: $color-info-600 !default;
$infoButtonActiveBg: $color-info-700 !default;
$infoButtonTextActiveColor: $infoButtonTextColor !default;
$infoButtonActiveBorderColor: $color-info-700 !default;
$infoButtonFocusShadow: 0 0 0 0.2rem $color-info-alfa-16 !default;

$successButtonBg: $color-success-500 !default;
$successButtonTextColor: $color-text-light !default;
$successButtonBorder: 1px solid $successButtonBg !default;
$successButtonHoverBg: $color-success-600 !default;
$successButtonTextHoverColor: $successButtonTextColor !default;
$successButtonHoverBorderColor: $color-success-600 !default;
$successButtonActiveBg: $color-success-700 !default;
$successButtonTextActiveColor: $successButtonTextColor !default;
$successButtonActiveBorderColor: $color-success-700 !default;
$successButtonFocusShadow: 0 0 0 0.2rem $color-success-alfa-16 !default;

$warningButtonBg: $color-warning-500 !default;
$warningButtonTextColor: $color-text-light !default;
$warningButtonBorder: 1px solid $warningButtonBg !default;
$warningButtonHoverBg: $color-warning-600 !default;
$warningButtonTextHoverColor: $warningButtonTextColor !default;
$warningButtonHoverBorderColor: $color-warning-600 !default;
$warningButtonActiveBg: $color-warning-700 !default;
$warningButtonTextActiveColor: $warningButtonTextColor !default;
$warningButtonActiveBorderColor: $color-warning-700 !default;
$warningButtonFocusShadow: 0 0 0 0.2rem $color-warning-alfa-16 !default;

$helpButtonBg: $color-tertiary-500 !default;
$helpButtonTextColor: $color-text-light !default;
$helpButtonBorder: 1px solid $helpButtonBg !default;
$helpButtonHoverBg: $color-tertiary-600 !default;
$helpButtonTextHoverColor: $helpButtonTextColor !default;
$helpButtonHoverBorderColor: $color-tertiary-600 !default;
$helpButtonActiveBg: $color-tertiary-700 !default;
$helpButtonTextActiveColor: $helpButtonTextColor !default;
$helpButtonActiveBorderColor: $color-tertiary-700 !default;
$helpButtonFocusShadow: 0 0 0 0.2rem $color-tertiary-alfa-16 !default;

$dangerButtonBg: $color-danger-500 !default;
$dangerButtonTextColor: $color-text-light !default;
$dangerButtonBorder: 1px solid $dangerButtonBg !default;
$dangerButtonHoverBg: $color-danger-600 !default;
$dangerButtonTextHoverColor: $dangerButtonTextColor !default;
$dangerButtonHoverBorderColor: $color-danger-600 !default;
$dangerButtonActiveBg: $color-danger-700 !default;
$dangerButtonTextActiveColor: $dangerButtonTextColor !default;
$dangerButtonActiveBorderColor: $color-danger-700 !default;
$dangerButtonFocusShadow: 0 0 0 0.2rem $color-danger-alfa-16 !default;

$linkButtonColor: $primaryDarkerColor !default;
$linkButtonHoverColor: $primaryDarkerColor !default;
$linkButtonTextHoverDecoration: underline !default;
$linkButtonFocusShadow: 0 0 0 0.2rem $focusOutlineColor !default;

//checkbox
$checkboxWidth: 1.25rem !default;
$checkboxHeight: 1.25rem !default;
$checkboxBorder: $inputBorder;
$checkboxIconFontSize: 0.625rem !default;
$checkboxActiveBorderColor: $primaryColor !default;
$checkboxActiveBg: $primaryColor !default;
$checkboxIconActiveColor: $primaryTextColor !default;
$checkboxActiveHoverBg: $primaryDarkerColor !default;
$checkboxIconActiveHoverColor: $primaryTextColor !default;
$checkboxActiveHoverBorderColor: $primaryDarkerColor !default;

//radiobutton
$radiobuttonWidth: 1.25rem !default;
$radiobuttonHeight: 1.25rem !default;
$radiobuttonBorder: $inputBorder;
$radiobuttonIconSize: 60% !default;
$radiobuttonActiveBorderColor: $primaryColor !default;
$radiobuttonActiveBg: $primaryTextColor !default;
$radiobuttonIconActiveColor: $primaryColor !default;
$radiobuttonActiveHoverBorderColor: $primaryDarkerColor !default;
$radiobuttonActiveHoverBg: $primaryTextColor !default;
$radiobuttonIconActiveHoverColor: $primaryDarkerColor !default;

//colorpicker
$colorPickerPreviewWidth: 2rem !default;
$colorPickerPreviewHeight: 2rem !default;
$colorPickerBg: #323232 !default;
$colorPickerBorder: 1px solid #191919 !default;
$colorPickerHandleColor: $shade000 !default;

//togglebutton
$toggleButtonBg: $inputBg !default;
$toggleButtonBorder: 1px solid $shade400 !default;
$toggleButtonTextColor: $shade700 !default;
$toggleButtonIconColor: $shade600 !default;
$toggleButtonHoverBg: $shade200 !default;
$toggleButtonHoverBorderColor: $shade400 !default;
$toggleButtonTextHoverColor: $shade700 !default;
$toggleButtonIconHoverColor: $shade800 !default;
$toggleButtonActiveBg: $primaryColor !default;
$toggleButtonActiveBorderColor: $primaryColor !default;
$toggleButtonTextActiveColor: $primaryTextColor !default;
$toggleButtonIconActiveColor: $primaryTextColor !default;
$toggleButtonActiveHoverBg: $primaryDarkColor !default;
$toggleButtonActiveHoverBorderColor: $primaryDarkColor !default;
$toggleButtonTextActiveHoverColor: $primaryTextColor !default;
$toggleButtonIconActiveHoverColor: $primaryTextColor !default;

//inplace
$inplacePadding: $inputPadding !default;
$inplaceHoverBg: $shade200 !default;
$inplaceTextHoverColor: $shade700 !default;

//rating
$ratingIconFontSize: 1.143rem !default;
$ratingCancelIconColor: #ea5455 !default;
$ratingCancelIconHoverColor: #e73d3e !default;
$ratingStarIconOffColor: $shade700 !default;
$ratingStarIconOnColor: $primaryColor !default;
$ratingStarIconHoverColor: $primaryColor !default;

//slider
$sliderBg: $shade300 !default;
$sliderBorder: 0 none !default;
$sliderHorizontalHeight: .286rem !default;
$sliderVerticalWidth: 0.286rem !default;
$sliderHandleWidth: 1.143rem !default;
$sliderHandleHeight: 1.143rem !default;
$sliderHandleBg: $shade000 !default;
$sliderHandleBorder: 2px solid $primaryColor !default;
$sliderHandleBorderRadius: 50% !default;
$sliderHandleHoverBorderColor: $primaryColor !default;
$sliderHandleHoverBg: $primaryColor !default;
$sliderRangeBg: $primaryColor !default;

//calendar
$calendarTableMargin: .5rem 0 !default;
$calendarPadding: .5rem !default;
$calendarBg: $shade000 !default;
$calendarInlineBg: $calendarBg !default;
$calendarTextColor: $shade700 !default;
$calendarBorder: $inputListBorder !default;
$calendarOverlayBorder: $inputOverlayBorder !default;

$calendarHeaderPadding: .5rem !default;
$calendarHeaderBg: $shade000 !default;
$calendarInlineHeaderBg: $calendarBg !default;
$calendarHeaderBorder: 1px solid $shade300 !default;
$calendarHeaderTextColor: $shade700 !default;
$calendarHeaderFontWeight: 600 !default;
$calendarHeaderCellPadding: .5rem !default;
$calendarMonthYearHeaderHoverTextColor:  $primaryColor !default;

$calendarCellDatePadding: .5rem !default;
$calendarCellDateWidth: 2.5rem !default;
$calendarCellDateHeight: 2.5rem !default;
$calendarCellDateBorderRadius: 50% !default;
$calendarCellDateBorder: 1px solid transparent !default;
$calendarCellDateHoverBg: $shade200 !default;
$calendarCellDateTodayBg: $shade400 !default;
$calendarCellDateTodayBorderColor: transparent !default;
$calendarCellDateTodayTextColor: $shade700 !default;

$calendarButtonBarPadding: 1rem 0 !default;
$calendarTimePickerPadding: .5rem !default;
$calendarTimePickerElementPadding: 0 .5rem !default;
$calendarTimePickerTimeFontSize: 1.25rem !default;

$calendarBreakpoint: 769px !default;
$calendarCellDatePaddingSM: 0 !default;

//input switch
$inputSwitchWidth: 3rem !default;
$inputSwitchHeight: 1.75rem !default;
$inputSwitchBorderRadius: 1.875rem !default;
$inputSwitchHandleWidth: 1.250rem !default;
$inputSwitchHandleHeight: 1.250rem !default;
$inputSwitchHandleBorderRadius: 50% !default;
$inputSwitchSliderPadding: .25rem !default;
$inputSwitchSliderOffBg: $shade400 !default;
$inputSwitchHandleOffBg: $shade000 !default;
$inputSwitchSliderOffHoverBg: scale-color($shade400, $lightness:  -10%) !default;
$inputSwitchSliderOnBg: $primaryColor !default;
$inputSwitchSliderOnHoverBg: $primaryDarkColor !default;
$inputSwitchHandleOnBg: $shade000 !default;

//panel
$panelHeaderBorderColor: $shade300 !default;
$panelHeaderBorder: 1px solid $shade300 !default;
$panelHeaderBg: $shade100 !default;
$panelHeaderTextColor: $shade800 !default;
$panelHeaderFontWeight: 500 !default;
$panelHeaderPadding: 0.5rem 1rem !default;
$panelToggleableHeaderPadding: .75rem 1.25rem !default;

$panelHeaderHoverBg: $shade200 !default;
$panelHeaderHoverBorderColor: $shade300 !default;
$panelHeaderTextHoverColor: $shade800 !default;

$panelContentBorderColor:  $shade300 !default;
$panelContentBorder: 1px solid $shade300 !default;
$panelContentBg: $shade000 !default;
$panelContentEvenRowBg: scale-color($panelContentBg, $lightness:  -1%) !default;
$panelContentTextColor: $shade700 !default;
$panelContentPadding: 1.25rem !default;

$panelFooterBorder: 1px solid $shade300 !default;
$panelFooterBg: $shade000 !default;
$panelFooterTextColor: $shade700 !default;
$panelFooterPadding: 0.75rem 1.25rem !default;

//accordion
$accordionSpacing: 4px !default;
$accordionHeaderBorder: $panelHeaderBorder !default;
$accordionHeaderBg: $panelHeaderBg !default;
$accordionHeaderTextColor: $shade600 !default;
$accordionHeaderFontWeight: $panelHeaderFontWeight !default;
$accordionHeaderPadding: $panelHeaderPadding !default;

$accordionHeaderHoverBg: $shade200 !default;
$accordionHeaderHoverBorderColor: $shade300 !default;
$accordionHeaderTextHoverColor: $shade800 !default;

$accordionHeaderActiveBg: $panelHeaderBg !default;
$accordionHeaderActiveBorderColor: $shade300 !default;
$accordionHeaderTextActiveColor: $shade800 !default;

$accordionHeaderActiveHoverBg: $shade200 !default;
$accordionHeaderActiveHoverBorderColor: $shade300 !default;
$accordionHeaderTextActiveHoverColor: $shade800 !default;

$accordionContentBorder: $panelContentBorder !default;
$accordionContentBg: $panelContentBg !default;
$accordionContentTextColor: $panelContentTextColor !default;
$accordionContentPadding: $panelContentPadding !default;

//tabview
$tabviewNavBorder: 1px solid $shade300 !default;
$tabviewNavBorderWidth: 0 0 1px 0 !default;
$tabviewNavBg: transparent !default;

$tabviewHeaderSpacing: 0 !default;
$tabviewHeaderBorder: solid $shade300 !default;
$tabviewHeaderBorderWidth: 0 0 3px 0 !default;
$tabviewHeaderBorderColor: transparent !default;
$tabviewHeaderBg: transparent !default;
$tabviewHeaderTextColor: $color-text-dark !default;
$tabviewHeaderFontFamily: $fontFamily !default;
$tabviewHeaderFontSize: $fontSize !default;
$tabviewHeaderLineHeight: 1.5 !default;
$tabviewHeaderFontWeight: $panelHeaderFontWeight !default;
$tabviewHeaderPadding: $panelHeaderPadding !default;
$tabviewHeaderMargin: 0 0 -1px 0 !default;

$tabviewHeaderHoverBg: $tabviewHeaderBg !default;
$tabviewHeaderHoverBorderColor: $primaryColor !default;
$tabviewHeaderTextHoverColor: $tabviewHeaderTextColor !default;

$tabviewHeaderActiveBg: $tabviewHeaderBg !default;
$tabviewHeaderActiveBorderColor: $tabviewHeaderHoverBorderColor !default;
$tabviewHeaderTextActiveColor: $tabviewHeaderTextColor !default;

$tabviewContentBorder: 0 none !default;
$tabviewContentBg: $panelContentBg !default;
$tabviewContentTextColor: $panelContentTextColor !default;
$tabviewContentPadding: $panelContentPadding !default;

//upload
$fileUploadProgressBarHeight: .25rem !default;
$fileUploadContentPadding: 2rem 1rem !default;

//scrollpanel
$scrollPanelTrackBorder: 0 none !default;
$scrollPanelTrackBg: $shade100 !default;

//card
$cardBodyPadding: 1.25rem !default;
$cardTitleFontSize: 1.5rem !default;
$cardTitleFontWeight: 700 !default;
$cardSubTitleFontWeight: 400 !default;
$cardSubTitleColor: $shade600 !default;
$cardContentPadding: 1.25rem 0 !default;
$cardFooterPadding: 1.25rem 0 0 0 !default;
$cardShadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12) !default;

//editor
$editorToolbarBg: $panelHeaderBg !default;
$editorToolbarBorder: $panelHeaderBorder !default;
$editorToolbarPadding: $panelHeaderPadding !default;
$editorToolbarIconColor: $textSecondaryColor !default;
$editorToolbarIconHoverColor: $textColor !default;
$editorIconActiveColor: $primaryColor !default;
$editorContentBorder: $panelContentBorder !default;
$editorContentBg: $panelContentBg !default;

//paginator
$paginatorBg: $shade000 !default;
$paginatorTextColor: $shade600 !default;
$paginatorBorder: solid $shade200 !default;
$paginatorBorderWidth: 0 !default;
$paginatorPadding: .5rem 1rem !default;
$paginatorElementWidth: $buttonIconOnlyWidth !default;
$paginatorElementHeight: $buttonIconOnlyWidth !default;
$paginatorElementBg: transparent !default;
$paginatorElementBorder: 0 none !default;
$paginatorElementIconColor: $shade600 !default;
$paginatorElementHoverBg: $shade200 !default;
$paginatorElementHoverBorderColor: transparent !default;
$paginatorElementIconHoverColor: $shade800 !default;
$paginatorElementBorderRadius: 50% !default;
$paginatorElementMargin: .143rem !default;
$paginatorElementPadding: 0 !default;

//table
$tableBorder: 1px solid $shade300 !default;
$tableBoxShadow: 0px 0.25rem 0.5rem 0px rgba(100, 116, 139, 0.16) !default;
$tableRowHeight: 3rem !default;

$tableHeaderBorder: 1px solid $color-basic-alfa-8 !default;
$tableHeaderBorderWidth: 1px 0 1px 0 !default;
$tableHeaderBg: $shade100 !default;
$tableHeaderTextColor: $shade800 !default;
$tableHeaderFontWeight: 700 !default;
$tableHeaderPadding: 0.375rem 0.75rem !default;

$tableHeaderCellPadding: 0.375rem 0.75rem !default;
$tableHeaderCellBg: $shade100 !default;
$tableHeaderCellTextColor: $shade800 !default;
$tableHeaderCellFontWeight: 500 !default;
$tableHeaderCellBorder: 1px solid $color-basic-alfa-8 !default;
$tableHeaderCellBorderWidth: 0 0 1px 0 !default;
$tableHeaderCellHoverBg: $shade200 !default;
$tableHeaderCellTextHoverColor: $shade800 !default;
$tableHeaderCellIconColor: $color-text-grey !default;
$tableHeaderCellIconHoverColor: $shade800 !default;
$tableHeaderCellHighlightBg: $highlightBg !default;
$tableHeaderCellHighlightTextColor: $highlightTextColor !default;
$tableHeaderCellHighlightHoverBg: $highlightBg !default;
$tableHeaderCellHighlightTextHoverColor: $highlightTextColor !default;
$tableSortableColumnBadgeSize: 1.143rem !default;

$tableBodyRowBg: $shade000 !default;
$tableBodyRowTextColor: $shade700 !default;
$tableBodyRowEvenBg: scale-color($tableBodyRowBg, $lightness:  -1%) !default;
$tableBodyRowHoverBg: $shade200 !default;
$tableBodyRowTextHoverColor: $shade700 !default;
$tableBodyCellBorder: 1px solid $color-basic-alfa-8 !default;
$tableBodyCellBorderWidth: 0 0 1px 0 !default;
$tableBodyCellPadding: 0.375rem 0.75rem !default;

$tableFooterCellPadding: 0.375rem 0.75rem !default;
$tableFooterCellBg: $shade100 !default;
$tableFooterCellTextColor: $shade800 !default;
$tableFooterCellFontWeight: 500 !default;
$tableFooterCellBorder: 1px solid $color-basic-alfa-8 !default;
$tableFooterCellBorderWidth: 0 0 1px 0 !default;
$tableResizerHelperBg: $primaryColor !default;

$tableFooterBorder: 1px solid $color-basic-alfa-8 !default;
$tableFooterBorderWidth: 0 0 1px 0 !default;
$tableFooterBg: $shade100 !default;
$tableFooterTextColor: $shade800 !default;
$tableFooterFontWeight: 500 !default;
$tableFooterPadding: 0.375rem 0.75rem !default;

$tableCellContentAlignment: left !default;
$tableTopPaginatorBorderWidth: 0 0 1px 0 !default;
$tableBottomPaginatorBorderWidth: 0 0 1px 0 !default;

$tableScaleSM: 0.5 !default;
$tableScaleLG: 1.25 !default;

//dataview
$dataViewContentPadding: 0 !default;
$dataViewContentBorder: 0 none !default;
$dataViewListItemBorder: solid $shade300 !default;
$dataViewListItemBorderWidth: 0 0 1px 0 !default;

//schedule
$fullCalendarEventBg: $primaryDarkColor !default;
$fullCalendarEventBorderColor:  $primaryDarkColor !default;
$fullCalendarEventBorder: 1px solid $primaryDarkColor !default;
$fullCalendarEventTextColor: $primaryTextColor !default;

//tree
$treeContainerPadding: 0.286rem !default;
$treeNodePadding: 0.143rem !default;
$treeNodeContentPadding: .5rem !default;
$treeNodeChildrenPadding: 0 0 0 1rem !default;
$treeNodeIconColor: $shade600 !default;

//timeline
$timelineVerticalEventContentPadding: 0 1rem !default;
$timelineHorizontalEventContentPadding: 1rem 0 !default;
$timelineEventMarkerWidth: 1rem !default;
$timelineEventMarkerHeight: 1rem !default;
$timelineEventMarkerBorderRadius: 50% !default;
$timelineEventMarkerBorder: 2px solid $primaryColor !default;
$timelineEventMarkerBackground: $primaryTextColor !default;
$timelineEventConnectorSize: 2px !default;
$timelineEventColor: $shade300 !default;

//org chart
$organizationChartConnectorColor: $shade300 !default;

//message
$messageMargin: 1rem 0 !default;
$messagePadding: 1.25rem 1.75rem !default;
$messageBorderWidth: 0 0 0 6px !default;
$messageIconFontSize: 1.5rem !default;
$messageTextFontSize: 1rem !default;
$messageTextFontWeight: 400 !default;

//inline message
$inlineMessagePadding: $inputPadding !default;
$inlineMessageMargin: 0 !default;
$inlineMessageIconFontSize: 1rem !default;
$inlineMessageTextFontSize: 1rem !default;
$inlineMessageBorderWidth: 0px !default;

//toast
$toastIconFontSize: 1.5rem !default;
$toastMessageTextMargin: 0 0.75rem !default;
$toastMargin: 0 0 1rem 0 !default;
$toastPadding: 1rem !default;
$toastBorderWidth: 1px !default;
$toastBorderRadius: 0.5rem !default;
$toastShadow: 0 6px 12px 0 rgba(0,0,0,.1) !default;
$toastOpacity: 1 !default;
$toastTitleFontWeight: 700 !default;
$toastDetailMargin: 0.25rem 0 0 0 !default;

//severities
$infoMessageBg: $color-info-alfa-8 !default;
$infoMessageBorder: solid $color-info-500 !default;
$infoMessageSummaryColor: $color-text-dark !default;
$infoMessageTextColor: $shade600 !default;
$infoMessageIconColor: $color-info-500 !default;
$infoMessageCloseIconColor: $color-text-grey-light !default;
$successMessageBg: $color-success-alfa-8 !default;
$successMessageBorder: solid $color-success-500 !default;
$successMessageSummaryColor: $color-text-dark !default;
$successMessageTextColor: $shade600 !default;
$successMessageIconColor: $color-success-500 !default;
$successMessageCloseIconColor: $color-text-grey-light !default;
$warningMessageBg: $color-warning-alfa-8 !default;
$warningMessageBorder: solid $color-warning-500 !default;
$warningMessageSummaryColor: $color-text-dark !default;
$warningMessageTextColor: $shade600 !default;
$warningMessageIconColor: $color-warning-500 !default;
$warningMessageCloseIconColor: $color-text-grey-light !default;
$errorMessageBg: $color-danger-alfa-8 !default;
$errorMessageBorder: solid $color-danger-500 !default;
$errorMessageSummaryColor: $color-text-dark !default;
$errorMessageTextColor: $shade600 !default;
$errorMessageIconColor: $color-danger-500 !default;
$errorMessageCloseIconColor: $color-text-grey-light !default;

//overlays
$overlayContentBorder: 0 none !default;
$overlayContentBg: $panelContentBg !default;
$overlayContainerShadow: 0 1px 3px rgba(0,0,0,.3) !default;

//dialog
$dialogHeaderBg: $shade000 !default;
$dialogHeaderBorder: 1px solid $shade400;
$dialogHeaderTextColor: $shade800 !default;
$dialogHeaderFontWeight: 700 !default;
$dialogHeaderFontSize: 1.125rem !default;
$dialogHeaderPadding: 1rem 1.5rem !default;
$dialogContentPadding: 1.5rem !default;
$dialogContentFontSize: 1rem;
$dialogFooterBorder: 1px solid $shade400;
$dialogFooterPadding: 1rem 1.5rem !default;
$dialogBorderRadius: 1rem;


//confirmpopup
$confirmPopupContentPadding: $panelContentPadding !default;
$confirmPopupFooterPadding: 0 1.25rem 1.25rem 1.25rem !default;

//tooltip
$tooltipBg: $shade700 !default;
$tooltipTextColor: $shade000 !default;
$tooltipPadding: $inputPadding !default;

//steps
$stepsItemBg: $shade000 !default;
$stepsItemBorder: 1px solid $shade200 !default;
$stepsItemTextColor: $shade600 !default;
$stepsItemNumberWidth: 2rem !default;
$stepsItemNumberHeight: 2rem !default;
$stepsItemNumberFontSize: 1.143rem !default;
$stepsItemNumberColor: $shade700 !default;
$stepsItemNumberBorderRadius: 50% !default;
$stepsItemActiveFontWeight: 700 !default;

//progressbar
$progressBarHeight: 1.5rem !default;
$progressBarBorder: 0 none !default;
$progressBarBg: $shade300 !default;
$progressBarValueBg: $primaryColor !default;
$progressBarValueTextColor: $primaryTextColor !default;

//menu (e.g. menu, menubar, tieredmenu)
$menuMinWidth: 12.5rem !default;
$menuMaxWidth: 20rem !default;
$menuMaxHeight: 15rem !default;
$menuBg: $shade000 !default;
$menuBorder: 1px solid $shade300 !default;
$menuTextColor: $shade700 !default;
$menuitemPadding: .5rem 1rem !default;
$menuitemBorderRadius: 0 !default;
$menuitemTextColor: $shade700 !default;
$menuitemIconColor: $shade600 !default;
$menuitemTextHoverColor: $shade700 !default;
$menuitemIconHoverColor: $shade600 !default;
$menuitemHoverBg: $shade200 !default;
$menuitemTextActiveColor: $highlightTextColor !default;
$menuitemIconActiveColor: $highlightTextColor !default;
$menuitemActiveBg: $highlightBg !default;
$menuitemSubmenuIconFontSize: .875rem !default;
$submenuHeaderMargin: 0 !default;
$submenuHeaderPadding: .75rem 1.25rem !default;
$submenuHeaderBg: $shade000 !default;
$submenuHeaderTextColor: $shade800 !default;
$submenuHeaderBorderRadius: 0 !default;
$submenuHeaderFontWeight: 700 !default;
$overlayMenuBg: $menuBg !default;
$overlayMenuBorder: 0 none !default;
$overlayMenuShadow: 0 2px 12px 0 rgba(0,0,0,.1) !default;
$verticalMenuPadding: .25rem 0 !default;
$verticalMenuitemMargin: 0 !default;
$menuSeparatorMargin: .25rem 0 !default;

$breadcrumbPadding: 1rem !default;
$breadcrumbBg: $menuBg !default;
$breadcrumbBorder: $menuBorder !default;
$breadcrumbItemTextColor: $menuitemTextColor !default;
$breadcrumbItemIconColor: $menuitemIconColor !default;
$breadcrumbLastItemTextColor: $menuitemTextColor !default;
$breadcrumbLastItemIconColor: $menuitemIconColor !default;
$breadcrumbSeparatorColor: $menuitemTextColor !default;

$horizontalMenuPadding: .5rem !default;
$horizontalMenuBg: $shade100 !default;
$horizontalMenuBorder: $menuBorder !default;
$horizontalMenuTextColor: $menuTextColor !default;
$horizontalMenuRootMenuitemPadding: $menuitemPadding !default;
$horizontalMenuRootMenuitemBorderRadius: $borderRadius !default;
$horizontalMenuRootMenuitemTextColor: $menuitemTextColor !default;
$horizontalMenuRootMenuitemIconColor: $menuitemIconColor !default;
$horizontalMenuRootMenuitemTextHoverColor: $menuitemTextHoverColor !default;
$horizontalMenuRootMenuitemIconHoverColor: $menuitemIconHoverColor !default;
$horizontalMenuRootMenuitemHoverBg: $menuitemHoverBg !default;
$horizontalMenuRootMenuitemTextActiveColor: $menuitemTextActiveColor !default;
$horizontalMenuRootMenuitemIconActiveColor: $menuitemIconActiveColor !default;
$horizontalMenuRootMenuitemActiveBg: $menuitemActiveBg !default;

//badge and tag
$badgeBg: $primaryColor !default;
$badgeTextColor: $primaryTextColor !default;
$badgeMinWidth: 1.5rem !default;
$badgeHeight: 1.5rem !default;
$badgeFontWeight: 700 !default;
$badgeFontSize: .75rem !default;

$tagPadding: .25rem .4rem !default;

//carousel
$carouselIndicatorsPadding: 1rem !default;
$carouselIndicatorBg: $shade400 !default;
$carouselIndicatorHoverBg: $shade500 !default;
$carouselIndicatorBorderRadius: 0 !default;
$carouselIndicatorWidth: 2rem !default;
$carouselIndicatorHeight: .5rem !default;

//galleria
$galleriaMaskBg: rgba(0,0,0,0.9) !default;
$galleriaCloseIconMargin: .5rem !default;
$galleriaCloseIconFontSize: 2rem !default;
$galleriaCloseIconBg: transparent !default;
$galleriaCloseIconColor: $shade100 !default;
$galleriaCloseIconHoverBg: rgba(255,255,255,0.1) !default;
$galleriaCloseIconHoverColor: $shade100 !default;
$galleriaCloseIconWidth: 4rem !default;
$galleriaCloseIconHeight: 4rem !default;
$galleriaCloseIconBorderRadius: 50% !default;

$galleriaItemNavigatorBg: transparent !default;
$galleriaItemNavigatorColor: $shade100 !default;
$galleriaItemNavigatorMargin: 0 .5rem !default;
$galleriaItemNavigatorFontSize: 2rem !default;
$galleriaItemNavigatorHoverBg: rgba(255,255,255,0.1) !default;
$galleriaItemNavigatorHoverColor: $shade100 !default;
$galleriaItemNavigatorWidth: 4rem !default;
$galleriaItemNavigatorHeight: 4rem !default;
$galleriaItemNavigatorBorderRadius: $borderRadius !default;

$galleriaCaptionBg: rgba(0,0,0,.5) !default;
$galleriaCaptionTextColor: $shade100 !default;
$galleriaCaptionPadding: 1rem !default;

$galleriaIndicatorsPadding: 1rem !default;
$galleriaIndicatorBg: $shade400 !default;
$galleriaIndicatorHoverBg: $shade500 !default;
$galleriaIndicatorBorderRadius: 50% !default;
$galleriaIndicatorWidth: 1rem !default;
$galleriaIndicatorHeight: 1rem !default;
$galleriaIndicatorsBgOnItem: rgba(0,0,0,.5) !default;
$galleriaIndicatorBgOnItem: rgba(255,255,255,.4) !default;
$galleriaIndicatorHoverBgOnItem: rgba(255,255,255,.6) !default;

$galleriaThumbnailContainerBg: rgba(0,0,0,.9) !default;
$galleriaThumbnailContainerPadding: 1rem .25rem !default;
$galleriaThumbnailNavigatorBg: transparent !default;
$galleriaThumbnailNavigatorColor: $shade100 !default;
$galleriaThumbnailNavigatorHoverBg: rgba(255,255,255,0.1) !default;
$galleriaThumbnailNavigatorHoverColor: $shade100 !default;
$galleriaThumbnailNavigatorBorderRadius: 50% !default;
$galleriaThumbnailNavigatorWidth: 2rem !default;
$galleriaThumbnailNavigatorHeight: 2rem !default;

//divider
$dividerHorizontalMargin: 1.25rem 0 !default;
$dividerHorizontalPadding: 0 1.25rem !default;
$dividerVerticalMargin: 0 1.25rem !default;
$dividerVerticalPadding: 1.25rem 0 !default;
$dividerSize: 1px !default;
$dividerColor: $shade300 !default;

//avatar
$avatarBg: $shade300 !default;
$avatarTextColor: $textColor !default;

//chip
$chipBg: $shade300 !default;
$chipTextColor: $textColor !default;
$chipBorderRadius:  16px !default;

//scrollTop
$scrollTopBg: rgba(0,0,0,0.7) !default;
$scrollTopHoverBg: rgba(0,0,0,0.8) !default;
$scrollTopWidth: 3rem !default;
$scrollTopHeight: 3rem !default;
$scrollTopBorderRadius: 50% !default;
$scrollTopFontSize: 1.5rem !default;
$scrollTopTextColor: $shade100 !default;

//skeleton
$skeletonBg: $shade300 !default;
$skeletonAnimationBg: rgba(255,255,255,0.4) !default;

//splitter
$splitterGutterBg: $shade100 !default;
$splitterGutterHandleBg: $shade300 !default;

//speeddial
$speedDialButtonWidth:  4rem !default;
$speedDialButtonHeight:  4rem !default;
$speedDialButtonIconFontSize:  1.3rem !default;
$speedDialActionWidth:  3rem !default;
$speedDialActionHeight:  3rem !default;
$speedDialActionBg:  $shade700 !default;
$speedDialActionHoverBg:  #022354 !default;
$speedDialActionTextColor:  #fff !default;
$speedDialActionTextHoverColor:  #fff !default;

//dock
$dockActionWidth:  4rem !default;
$dockActionHeight:  4rem !default;
$dockItemPadding:  .5rem !default;
$dockCurrentItemMargin:  1.5rem !default;
$dockFirstItemsMargin:  1.3rem !default;
$dockSecondItemsMargin:  0.9rem !default;
$dockBg:  rgba(255,255,255,.1) !default;
$dockBorder:  1px solid rgba(255,255,255,0.2) !default;
$dockPadding:  .5rem .5rem !default;
$dockBorderRadius:  .5rem !default;

//image
$imageMaskBg: rgba(0,0,0,0.9) !default;
$imagePreviewToolbarPadding: 1rem !default;
$imagePreviewIndicatorColor: #f8f9fa !default;
$imagePreviewIndicatorBg: rgba(0,0,0,0.5) !default;
$imagePreviewActionIconBg: transparent !default;
$imagePreviewActionIconColor: #f8f9fa !default;
$imagePreviewActionIconHoverBg: rgba(255,255,255,0.1) !default;
$imagePreviewActionIconHoverColor: #f8f9fa !default;
$imagePreviewActionIconWidth: 3rem !default;
$imagePreviewActionIconHeight: 3rem !default;
$imagePreviewActionIconFontSize: 1.5rem !default;
$imagePreviewActionIconBorderRadius: 50% !default;

