@import 'conf/conf';

.investment-selector-container {
  text-align: left;

  .chosen-details {
    justify-content: center;
  }

  .screener-input {
    margin-right: 10px;
  }

  .screener-tab-body {
    min-height: 400px;
  }

  .screener-footer-buttons {
    //display: flex;

    button {
      //height: 50px;
    }
  }

  .fund-family-input {
    width: 30%;
    margin-right: 10px;

    .mat-form-field-wrapper {

      .mat-form-field-infix {

        .mat-chip.mat-standard-chip .mat-chip-remove {
          //color: var(--white);
          //min-height: 0 !important;
        }
      }

      .fund-family-chip-list {
        overflow-x: scroll;
        white-space: nowrap;
      }

      ::-webkit-scrollbar {
        height: 5px;
      }

      div.mat-chip-list-wrapper {
        flex-wrap: nowrap;
        overflow-x: auto;
        //width: 300px;
        white-space: nowrap;

        .mat-chip {
          margin: 0 4px;
          flex: 0 0 auto;
        }
      }

      .mat-chip-list {
        margin-left: 2px;
      }
    }
  }

  .selection-header-wrapper {
    padding: 10px 0;
    display: flex;

    .screener-selection-header {
      margin-top: 18px;
      margin-left: 30px;
      font-weight: bolder;
      color: $primary;
      font-size: 16px;
      display: inline;
    }
  }

  .screener-nav-btns {
    margin-top: 9px;
    flex-grow: 1;
  }

  .screener-text-align {
    text-align: center;
  }


}

.nb-theme-default h3, .nb-theme-default .h3 {
  font-size: 20px !important;
  font-weight: 500;
}
