@import '../conf/conf';

@-webkit-keyframes spin {
  0% {
    transform: rotate(0deg); /* Firefox 16+, IE 10+, Opera */
  }
  100% {
    transform: rotate(360deg); /* Firefox 16+, IE 10+, Opera */
  }
}

@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg); /* Firefox 16+*/
  }
  100% {
    -moz-transform: rotate(360deg); /* Firefox 16+*/
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg); /* Firefox 16+, IE 10+, Opera */
  }
  100% {
    transform: rotate(360deg); /* Firefox 16+, IE 10+, Opera */
  }
}

.icon-spinner {
  position: absolute;
  top: 0;
  left: 0;
  width: 22px;
  height: 24px;
  z-index: -10;
  background: transparent; //#207bbc;

  &.mobile-spinner {
    top: -6px;
  }

  & > div {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 26px;
    height: 26px;
    //margin: -75px 0 0 -75px;
    border-radius: 90%;
    border: 1px solid #000000;
    // override border right, bottom and left color
    border-right-color: $primary-dark;
    border-bottom-color: $primary;
    border-left-color: $secondary;
    transform: translate3d(0, 0, 0);
    animation: spin 1s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */

  }
}
