/* You can add global styles to this file, and also import other style files */
//@import "@angular/material/prebuilt-themes/deeppurple-amber.css";
//@import "@angular/material/prebuilt-themes/pink-bluegrey.css";
@import '@angular/material/prebuilt-themes/indigo-pink.css';
//@import "@angular/material/prebuilt-themes/purple-green.css";
@import './app/theme/sass/conf/conf';
@import './app/theme/sass/dialogs';

.ngx-datatable {
  margin: 0;
}

// set mat-dialog content sizes based on screen sizes
@media screen and (min-width: 650px) and (max-width: 850px) {
  .mat-dialog-content {
    min-height: 40vh;
    max-height: 70vh !important;
  }
}

@media (min-height: 850px) {
  .mat-dialog-content {
    &.xl-dialog {
      min-height: 50vh;
      max-height: 80vh;
    }

    &.lg-dialog {
      min-height: 50vh;
      max-height: 75vh;
    }
  }
}

.mat-horizontal-stepper-header {
  height: 48px !important;
}

.mat-dialog-title {
  margin: 0 0 10px !important;
  font-family: Inter, sans-serif !important;
  padding-bottom: 35px;
}

.nb-theme-default nb-accordion nb-accordion-item-header,
.nb-theme-default nb-card-header {
  font-family: Inter, sans-serif !important;
}

html {
  overflow-x: hidden;
}

//
.cdk-overlay-container {
  z-index: 100010 !important;
}

// remove stepper header slider for remove buttons in manager accordions
.manager-accordion,
.login-card {
  .mat-horizontal-stepper-header-container {
    display: none !important;
  }
}

.mat-select-panel {
  max-height: 600px !important;
}

// extension for the bottom of the login/registration pages
.bottom-extension {
  height: 1100px;
}

// settings for material buttons
.mat-button,

.mat-raised-button {
  margin-left: 5px !important;
  margin-right: 5px !important;

}

// settings for material button groups
.mat-button-group {
  width: 100%;

  &.row {
    margin: 5px;
    //border: rgba(178,178,178,0.35) solid 1px;
  }

  .col-1,
  .col-10 {
    padding: 0;
    margin: 0;
  }

  .mat-raised-button {
    height: 37px;
    width: 100%;
    margin: 0;
    min-width: 20px !important;
    padding: 0;
    border-radius: 0 !important;
  }
}

button {
  &.close {
    cursor: pointer;
  }
}

h3 {
  background-color: transparent !important;
  color: $secondary !important;
  height: 38px !important;
}

nb-card-header.ripsaw-page-header {
  font-family: Inter, sans-serif !important;
  font-size: 1.4rem;
  flex: 1;
  font-weight: bold;
  display: flex;
  align-items: center;
  position: relative;
}

.ripsaw-card-header {
  width: 100%;
  text-align: center;
  font-family: Inter, sans-serif !important;
  //color: $primary;
  color: black;
  font-size: 1.4rem;
  line-height: 1.6rem;
  padding-bottom: 15px;

  .ripsaw-card-subheader {
    font-size: 1rem;
    text-align: center;
    font-weight: 600;
    padding-top: 10px;
    padding-bottom: 25px;
    color: $secondary !important;

    @media screen and (max-width: 1200px) {
      font-size: 0.8rem;
      font-weight: 600;
    }
  }
}

.legal-link {
  margin-left: 5px;
  cursor: pointer;
  color: #79b0d7 !important;

  span {
    font-size: 13px;
    height: 24px;
    position: relative;
    top: -2px;
  }
}

// get rid of button outline after click
button:focus {
  outline: none;
}

/*.profile-menu, .help-menu {
  .mat-menu-content {
    width: $sidebar-width;
  }
}*/

.help-menu {
  position: absolute;
  top: -30px !important;
  left: 40px;
}

.profile-menu {
  position: absolute;
  bottom: 25px !important;
  left: 40px;
}

.notifications-menu {
  position: absolute;
  bottom: 65px !important;
  left: 40px;
}

.revision-menu {
  position: absolute;
  left: 40px;
}

.mat-menu-panel {
  max-width: none !important;

  .mat-menu-content {
    max-height: 50vh;

    .rev-menu-button {
      display: flex;
      justify-content: space-between;

      .rev-menu-item-name {
        text-overflow: ellipsis;
        max-width: 270px;
        overflow-x: hidden;
      }

      .rev-menu-item-date,
      .rev-menu-item-name {
        margin-right: 5px;
        flex: 1;
      }
    }
  }
}

.modal-header {
}

.modal-body {
  //position: absolute;
  //width: 100%;
  //bottom: 100px;
  //top: 0;
  overflow-y: auto;

  &.no-scroll {
    overflow-y: hidden;
  }
}

datatable-scroller {
  width: 100% !important;
}

.row-detail-container {
  width: 90vw;
}

.modal-footer {
  /*position: absolute;
  width: 100%;
  bottom: 0;
  padding-right: 25px;*/
}

.modal-footer-divider {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.modal-header-divider {
  margin-top: 10px !important;
  margin-bottom: 15px !important;
}

.modal-footer-btn {
  width: auto !important;
  height: 40px;
  margin-bottom: 20px !important;
}

.mat-select-panel {
  &.state-panel {
    max-height: 30vh !important;
    font-size: 1rem !important;
  }

  &.medium-panel {
    max-height: 50vh !important;
  }

  max-height: 80vh !important;
}

.mat-form-field-infix {
  label {
    width: 100%;
  }
}

.horizontal-form-group {
  display: flex;
  gap: 5px;
  align-items: center;
}

/*.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0.25em 0 !important;
}*/

.status-icon {
  margin-left: 5px;
  cursor: pointer;
}

///////////////////////////////////////////////////

.institution-icon {
  margin-right: 5px;
  height: 16px;
}

///////////////////////////////////////////////////

.title-with-disclaimer {
  position: relative;
  top: -6px;
}

.disclaimer,
.definition {
  cursor: pointer;
  vertical-align: super;
  font-size: 1em;
  color: $primary;

  &.form-disclaimer {
    padding-top: 10px;
  }
}

.form-info,
.info-icon {
  padding-top: 10px;
  color: $primary;
  cursor: pointer;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.group-left-side {
  border-left: black solid 2px;
}

.group-right-side {
  border-right: black solid 2px;
}

@-webkit-keyframes fadeOutBackground {
  0% {
    background-color: rgba($primary, 0.7);
  }

  100% {
    background-color: rgba($primary, 0.1);
  }
}

@keyframes fadeOutBackground {
  0% {
    background-color: rgba($primary, 0.7);
  }

  100% {
    background-color: rgba($primary, 0.1);
  }
}

.highlight-column {
  -webkit-animation-name: fadeOutBackground;
  animation-name: fadeOutBackground;
  -webkit-animation-duration: 10s;
  animation-duration: 10s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.rip-link {
  color: $active-link !important;
  cursor: pointer;

  &:hover {
    color: $hover-link !important;
  }
}

.raised-box {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
  0 1px 5px 0 rgba(0, 0, 0, 0.12);
  margin: 20px 0;
  padding: 20px;
}

div.page__content {
  overflow-x: hidden !important;
}

html,
body {
  -ms-overflow-style: none;

  ::-webkit-scrollbar {
    //display: none;
  }
}

.up {
  color: green !important;
}

.down {
  color: indianred !important;
}

.footnote {
  font-size: 0.7em;
}

@-webkit-keyframes refresh-indicator-animation {
  0%,
  49% {
    color: $secondary;
  }

  50%,
  100% {
    color: $primary;
  }
}

.refreshing-indicator {
  color: $secondary;
  margin-left: 20px;
  position: relative;
  top: 3px;
  padding: 5px;
  font-size: 16px;
  -webkit-animation: refresh-indicator-animation 1s infinite;
  /* Safari 4+ */
  -moz-animation: refresh-indicator-animation 1s infinite;
  /* Fx 5+ */
  -o-animation: refresh-indicator-animation 1s infinite;
  /* Opera 12+ */
  animation: refresh-indicator-animation 1s infinite;
  /* IE 10+, Fx 29+ */
}

.last-sync-label {
  margin-left: 5px;
  font-size: 9pt;
  color: $secondary;
  font-style: italic;
}

@-webkit-keyframes pulse-animation {
  0% {
    transform: scale(0.4);
    //box-shadow: 0 0 0 10px rgba($primary, 0.7);
  }

  70% {
    transform: scale(1.1);
    //box-shadow: 0 0 0 0 rgba($primary, 0);
  }

  100% {
    transform: scale(0.4);
    //box-shadow: 0 0 0 10px rgba($primary, 0);
  }
}

.pulse {
  //background: $primary;
  //border-radius: 5%;
  //box-shadow: 0 0 0 0 rgba($primary, 1);
  //margin: 5px;
  //width: 21px;
  //height: 21px;
  transform: scale(1);
  animation: pulse-animation 2s infinite;
}

// for the account settings dialog
#remove-manual-account-stepper > div.mat-horizontal-stepper-header-container {
  display: none !important;
}

.account-action-button {
  margin-left: 5px;
  color: $primary;
}

.mat-dialog-actions {
  margin-bottom: -15px !important;
}

.notification-badge-icon {
  position: relative;
  zoom: 150%;
  top: -4px;
  left: 12px;

  .fa-layers-counter {
    background-color: $primary;
  }
}

.notification-menu-badge-icon {
  position: relative;
  zoom: 200%;
  top: -3px;
  left: 9px;

  .fa-layers-counter {
    background-color: $primary;
  }
}

.main-layout-column {
  padding: 0 !important;
  height: 100vh;
  overflow-y: scroll;
}

.nb-theme-default nb-layout .layout {
  background-color: $color-bg-light-300;
}

.max-select-box-height {
  max-height: 300px !important;
}

@media (max-width: 500px) {
  .cdk-overlay-pane.rip-popover {
    max-width: 99vw !important;
  }
}

@media (min-width: 500px) {
  .cdk-overlay-pane.rip-popover {
    max-width: 50vw !important;
  }
}

.cdk-overlay-pane.rip-popover {
  nb-card {
    margin-bottom: 0;

    .popover-content {
      padding: 10px;
    }
  }

  nb-card-header.popover-title,
  mat-card-title.popover-title {
    color: $primary;
  }
}

.g-pay-icon {
  color: #fff;
  position: absolute;
  background-origin: content-box;
  background: #000000 url('https://js.stripe.com/v3/fingerprinted/img/dark-8191afec51483e108a2dc5f17fb0efd0.svg') no-repeat 50%;
  background-size: 0.85em;
  width: 1.125em;
  height: 0.87em;
  margin-left: 10px;
  margin-top: 0.06em;
  border-radius: 2px;
}

fa-icon.cc-icon.apple-pay-icon {
  color: #000000;
}

.cc-icon {
  color: $sidebar-dark;
  margin-left: 10px;
}

.growl-popover {
  //border: solid 1px $primary;
  border-radius: 20px;
  overflow: hidden;
  border: solid 2px $primary;
}

.popover-card {
  margin: 0 !important;
  padding: 0;

  nb-card-body,

  .mat-card-content {
    padding: 0 0 0 10px;
    overflow: visible;
  }

  .growl {
    color: $secondary;
    font-size: 1.1rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    //border: solid 1px $primary;
  }

  .growl-close {
    cursor: pointer !important;
    margin-left: 5px;
    background-color: white !important;
    color: gray !important;
    width: 30px !important;
    padding: 0 !important;
    min-width: 0 !important;
  }
}

// Override colors
.missing-data {
  color: red;

  .mat-form-field-label-wrapper {
    label.mat-form-field-label {
      color: red !important;
    }
  }
}

.override-in-place {
  color: purple;

  input {
    color: black;
  }

  .mat-form-field-label-wrapper {
    label {
      color: purple;
    }
  }
}

.provider-value {
  color: rgba(0, 0, 0, 0.54);
}

.legend-container {
  &.right {
    text-align: right;
  }

  &.left {
    text-align: left;
  }
}

.override-button {
  cursor: pointer;
  color: $primary;
  font-size: 18px;

  &.missing-data {
    color: red;
  }
}

.active-page {
  background-color: $primary;
}

.nb-theme-default nb-tabset .tab.active .tab-link,
.nb-theme-default nb-tabset .tab:hover .tab-link {
  color: $secondary;
}

.nb-theme-default nb-tabset .tab.active .tab-link::before,
.nb-theme-default nb-tabset .tab:hover .tab-link::before {
  background-color: $secondary;
}

.scrollable-toolbar {
  overflow-x: scroll;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    height: 0.25em;
  }

  .scrollable-toolbar-content {
    width: max-content;
  }
}

.proxy-footnote-item {
  margin: 0 10px;
}

.button-badge {
  cursor: pointer;
}

.flexing-row-only,
.flexing {
  display: flex;

  // the safe keyword is not supported in all browsers but seems to work in chrome, so I'm gonna use it
  //noinspection CssInvalidPropertyValue
  &.space-between-safe {
    justify-content: safe space-between;
  }

  &.space-between {
    justify-content: space-between;
  }

  &.with-gap {
    gap: 1%;
  }

  &.ten-px-gap {
    gap: 10px;
  }

  //noinspection CssInvalidPropertyValue
  &.space-around-safe {
    justify-content: safe space-around;
  }

  &.space-around {
    justify-content: space-around;
  }

  &.center-horizontally {
    justify-content: safe center;
  }

  &.vertical-flex {
    flex-direction: column;
  }

  &.center-vertically {
    align-items: safe center;
  }

  &.start {
    justify-content: safe start;
  }

  &.end {
    justify-content: safe end;
  }

  &.flex-table-row {
    flex-flow: row wrap;
  }
}

@media screen and (max-width: 1300px) {
  .flexing {
    flex-direction: column;
  }
}

.odd-setting-item {
  padding: 0;
  background: #eeeeee;
  border-radius: 10px;
}

.setting-item {
  padding-left: 10px;
}

.market-info-card-title,
.benchmark-accordion-title {
  font-size: 1.1rem;
  //color: $primary;
  color: black;

  @media screen and (max-width: 800px) {
    font-size: 1rem;
  }
}

.market-info-main-card {
  margin: 10px;

  > nb-card-header {
    padding: 0 5px 0 5px;
  }
}

#market-info-dashboard-title {
  margin-bottom: 10px;
  font-size: 1.5rem;
  //color: #79afd7;
  color: $secondary;
  flex: 1;
  display: flex;
  padding: 5px 7px;

  @media screen and (min-width: 1000px) {
    position: absolute;
    margin-top: 10px;
  }
}

#market-info-dashboard-header {
  .tradingview-widget-copyright {
    text-align: right !important;
    padding-right: 10px;
  }
}

#market-info-dashboard-content {
  nb-tab {
    border: solid 1px $secondary;
    border-radius: 5px;
  }

  nb-tabset .tab.active .tab-link,
  nb-tabset .tab:hover .tab-link {
    border: solid 1px $secondary;
    border-radius: 5px 5px 0 0;
    background: $secondary;
    color: white;
  }

  nb-tabset .tab .tab-link {
    border: solid 1px rgba(0, 0, 0, 0.1);
    border-radius: 5px 5px 0 0;
    font-size: 0.9rem;
    padding: 0.5rem;

    svg {
      margin-right: 5px;
    }
  }

  nb-tabset .tabset {
    justify-content: center;
    padding-top: 12px;
  }

  nb-tabset .tab {
    padding: 0;
    line-height: 1rem;
  }

  @media screen and (max-width: 800px) {
    nb-tabset .tab .tab-link {
      background-color: rgba(0, 0, 0, 0);
      cursor: pointer;
      padding: 2px;
      color: #8f9bb3;
      font-family: Open Sans, sans-serif;
      font-size: 0.75rem;
      font-weight: 700;
      line-height: 1rem;
      text-transform: uppercase;
      align-items: center;
      height: 100%;
    }
  }
}

.market-info-ticker-tape {
  flex: 4;
  max-height: 30rem;

  rip-trading-view-market-overview,
  rip-rss-app-magazine,
  rip-rss-app-list,
  rip-rss-app-wall,
  rip-rss-app-feed,
  rip-rss-app-carousel {
    padding: 0 10px;
  }
}

#expand-overview {
  width: 100%;
  display: flex;

  .expand-container {
    text-align: center;
    flex: 1;

    &:hover {
      background: rgb(240, 243, 244);
    }

    mat-icon {
      transform: scale(1.5, 1);
      line-height: 0.8;
    }
  }
}

.market-info-blurb {
  width: 93%;
  text-align: left;
  margin-left: 4%;
  margin-top: 10px;
  font-size: 0.9rem;
  background-color: rgb(243, 246, 250);
  border-radius: 10px;
  padding: 10px;
  color: $secondary;

  ul {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
  }

  li {
    color: $secondary;
  }

  .equation {
    margin-left: 30px;
    max-width: 65%;
    height: 38px;

    @media screen and (max-width: 800px) {
      max-width: 100%;
      margin-left: 0;
    }
  }

  .short-equation {
    margin-left: 30px;
    max-width: 50%;
    height: 20px;

    @media screen and (max-width: 800px) {
      max-width: 100%;
      margin-left: 0;
    }
  }
}

.forward-looking-table {
  width: 100% !important;

  overflow: hidden !important;

  @media screen and (max-width: 800px) {
    overflow: scroll !important;
  }

  ngx-datatable {
    width: 100%;
  }

  .ngx-datatable.fixed-header
  .datatable-header
  .datatable-header-inner
  .datatable-header-cell {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: normal !important;
    text-align: center !important;
    vertical-align: middle !important;
  }
}

.forward-looking-subheader {
  flex: 1;

  @media screen and (max-width: 800px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.forward-looking-subheader-label {
  @media screen and (max-width: 800px) {
    font-size: 0.8rem;
  }
}

.forward-looking-subheader-number {
  font-size: 1.5rem;

  @media screen and (max-width: 800px) {
    font-size: 0.75rem;
  }
}

.proxied-footnote {
}

#public-market-info-layout .layout {
  background-color: #ffffff !important;

  #public-market-info-header {
    background: $secondary;

    nav {
      width: 100% !important;
      position: relative;
      background: $secondary;
      overflow: hidden;
    }

    @media screen and (max-width: 800px) {
      nb-card-header {
        padding: 0;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .hide-on-mobile {
    display: none;
  }
}

#date-range-button-group {
  .mat-button-toggle.mat-button-toggle-appearance-standard
  div.mat-button-toggle-label-content {
    padding: 0 8px;
    line-height: 30px;
  }
}

.term-button-group {
  .mat-button-toggle.mat-button-toggle-appearance-standard
  span.mat-button-toggle-label-content {
    padding: 0 8px;
    line-height: 30px;
    font-size: 1rem;
  }
}

.no-padding-card-body {
  padding: 0 !important;

  &.no-scroll-horizontal {
    overflow-x: hidden !important;
  }

  &.no-scroll-vertical {
    overflow-y: hidden !important;
  }

  &.no-scroll {
    overflow: hidden !important;
  }
}

// this is for google maps places autocomplete widget
.pac-container {
  z-index: 1000000;
}

.positive-dollar-amount {
  color: green;
}

.negative-dollar-amount {
  color: red;
}

.canvas-container {
  overflow: hidden;
}

#add-manual-account-stepper {
  .mat-horizontal-content-container {
    padding: 0;
  }

  .tiles-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;

    .account-type-tile-button {
      font-size: 0.75rem;
      min-width: 200px;
      max-width: 200px;
      margin: 10px;
    }

    .account-type-tile-button {
      height: 70px;
      white-space: normal;
      flex: 1;
      width: 200px;

      &:hover {
        background-color: rgba(130, 130, 130, 0.68);
      }
    }
  }
}

.manual-account-icon {
  color: $secondary;
}

#benchmark-setup-layout .layout {
  background-color: #fff;
}

.animated-transition {
  transition: all 0.5s;
}

rip-investor-info {
  width: 100%;
  margin-bottom: 10px;
}

#onboarding-layout-header {
  nav {
    width: 100% !important;
    position: relative;
    background: $secondary;
    overflow: hidden;
    box-shadow: none;
  }
}

#onboarding-footer nav {
  color: white;
  width: 100% !important;
  position: relative;
  background: $secondary;
  overflow: hidden;
  box-shadow: none;
}

rip-wealth-plan-analysis .analysis-container,
.household-members-container,
#investor-info-container,
#goal-contributions-container,
#wealth-plan-container,
rip-wealth-forecast .expected-value-timeline-numbers,
.scale-control-container {
  border: solid 1px $border;
  border-radius: 8px;
  padding: 5px;
  //width: 100%;
  flex: 1;
  align-items: center;
}

.household-members-container {
  margin: 10px 0;
}

#wealth-plan-container {
  //margin-top: 17px;
}

.planning-sidebar-titles {
  width: 100%;
  text-align: center;
  font-family: Inter, sans-serif !important;
  color: $primary;
  font-size: 1.3rem;
  padding-bottom: 3px;
  padding-top: 3px;
  position: relative;
}

.wealth-forecast-row {
  border-bottom: lightblue solid;
  width: 95%;
}

rip-wealth-probability-numbers {
  width: 100%;
}

.expected-value-timeline-numbers {
  height: 100%;
}

.expected-wealth,
.downside-risk,
.upside-potential {
  min-width: 24%;
  text-align: right;
  //display: flex;
  //align-items: baseline;
  //justify-content: end;
  //gap: 2%;
  flex: 1;

  @media screen and (max-width: 800px) {
    font-size: 0.75rem;
  }
}

.wealth-probability-header {
  text-align: left;
  flex: 1;

  @media screen and (max-width: 800px) {
    font-size: 0.75rem;
    text-align: right;
  }
}

.downside-risk {
  padding-right: 5px;
}

.expected-wealth {
  color: #5ad45a;
}

.downside-risk {
  color: #ea5545;
}

.upside-potential {
  color: #27aeef;
}

.scale-control-container {
  flex: 0;

  .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    padding: 0 5px;
  }

  /*min-width: 450px;
  max-width: 450px;*/
}

.two-button-tiles {
  width: 49%;
}

.three-button-tiles {
  flex: 1;
}

.five-button-tiles {
  width: 19%;

  @media screen and (max-width: 1000px) {
    width: 100%;
  }

  .mat-button-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 10px 5px;
  }
}

.button-bullet-list {
  margin-top: 25px;
  text-align: left;

  /* H6 Ripsaw */
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 23px;
  letter-spacing: 0.0015em;

  color: #000000;

  @media screen and (max-width: 800px) {
    font-size: 0.75rem;
  }

  ul {
    list-style: circle;
    //overflow: hidden;

    li {
      max-width: 100%;
      white-space: normal;
    }
  }
}

.sample-proxy-buttons {
  justify-content: center;
  margin: 10px;
}

.ngx-datatable.material.striped .datatable-body {
  // first added for yield curve table to remove padding on the cell so the cell itself could do it (and add a background color)
  .no-padding-cell {
    padding: 0 !important;
  }

  .boxed-table-cell {
    border-top: solid $primary 2px !important;
    border-bottom: solid $primary 2px !important;
  }

  .boxed-table-cell-first {
    border-left: solid $primary 2px !important;
  }

  .boxed-table-cell-last {
    border-right: solid $primary 2px !important;
  }
}

.settings-panel .mat-dialog-container {
  resize: both !important;
  overflow: hidden;
  min-width: 750px;
  min-height: 500px;
  max-width: 90vw !important;
  max-height: 90vh !important;
}

.change-subscription-dialog-panel .mat-dialog-container {
  overflow-x: hidden;
  padding: 0 24px 10px 24px;
}

rip-market-info-settings {
  .mat-form-field-appearance-outline .mat-form-field-prefix,
  .mat-form-field-appearance-outline .mat-form-field-suffix {
    top: 0;
  }
}

.border-on-top {
  border-top: 2px $primary solid !important;
}

.border-on-bottom {
  border-bottom: 2px $primary solid !important;
}

.border-on-sides {
  border-left: 2px $primary solid !important;
  border-right: 2px $primary solid !important;
}

.rip-yield-table {
  width: 100% !important;
  //overflow-x: auto !important;

  datatable-header {
    //width: auto !important;
  }
}

.dialog-error-msg {
  color: red;
}

.household-member-dialog {
  mat-dialog-container {
    overflow: hidden;

    ng-component {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
    }

    .mat-dialog-content {
      //max-height: calc(60vh - 100px);
    }
  }
}

.in-chart-icon {
  cursor: pointer;
}

.goal-btn-container {
  flex: 1;

  button {
    margin: 5px;
    padding: 5px 16px;
  }

  fa-icon {
    color: $primary;
  }
}

.goal-icon {
  color: $primary;
  margin-right: 5px;
}

rip-user-goal-generic-form,
rip-user-goal-retirement-form,
rip-user-goal-no-withdrawal-form {
  flex: 1;
}

rip-expected-wealth-chart {
  padding-bottom: 50px;
}

rip-wealth-chart-zoom-control {
  width: 100%;
}

.ngx-colors-overlay {
  z-index: 1000001 !important;
}

.planning-sidebar {
  min-width: 350px;
  max-width: 350px;
}

.deal-heading {
  font-size: 1.4rem;
  font-family: Inter, sans-serif;
  line-height: 1.75rem;
  color: $primary;
}

.deal-heading-small {
  font-size: 1rem;
  color: $primary;
  //font-family: Raleway, sans-serif;
}

.cdk-overlay-container .context-menu {
  overflow-y: auto;
  max-height: 95vh;
}

.no-header-stepper .mat-horizontal-stepper-header-container {
  display: none !important;
}

.tab-header-icon {
  color: $primary;
  margin-right: 10px;
}

.explainer {
  color: gray;
  border: solid darkgray;
  border-radius: 5px;
  padding: 10px;
  flex: 1;
  margin-top: 20px;

  li {
    font-size: 0.75rem;
    color: gray;
  }
}

fa-layers {
  fa-stack {
    margin: 5px 10px 5px 0;

    .icon-subscript-right {
      position: relative;
      top: 2px;
      left: -55px;
      //color: $primary;
      font-size: 0.2rem !important;

      &.with-subscript-left {
        left: -105px;
      }
    }

    .icon-subscript-left {
      position: relative;
      top: 2px;
      left: -70px;
      //color: $primary;
      font-size: 0.2rem !important;
    }
  }
}

.mat-dialog-container {
  position: relative;
  border-radius: 8px !important;
}

::ng-deep .p-toast {
  z-index: 100011 !important;
}

.auto-sync-info-overlay {
  width: 450px;
  &:before, &:after {
    content:none !important;
  }

}

rip-nebular-mobile-layout {
  height: 100%;
}

@media screen and (max-width: 36rem) {
  .nb-theme-default nb-tabset .tab.responsive .tab-text {
     display: block !important;
  }
}